.Plantel{
    display: flex;
}
main{
    width: 75vw;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(3, auto);
    gap: 20px;
    justify-items: center;
    justify-content: space-around;
}
.player{
    width: 209px;
    height: 272px;
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 10px 0;
    position: relative;
}
.player span{
    color: #d4dad9;
    font-weight: 800;
    font-size: xxx-large;   
    width: fit-content;
    padding: 0 5px;
}
.player nav{
    width: 100%;
    min-height: 100px;
    display: flex; 
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 10px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(rgba(210, 0, 0, 0), rgba(210, 0, 0, 0.089),rgba(210, 0, 0, 0.336), rgba(210, 0, 0, 0.61),rgba(210, 0, 0, 0.815), rgba(210, 0, 0, 0.93));
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.player img{
    width: auto;
    height: 100%;
    border-radius: 20px;
}

.player h3{
    margin: 0;
    text-align: left;
    padding-left: 20px;
    width: 190px;
    color: white;
    height: 53px;
}
.player p{
    font-weight: 500;
}
.cornerRB::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid #ffffff;
    width: 0;
    height: 0;
  }
  .cornerLT::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 10px solid rgba(0, 0, 0, 0);
    border-left: 10px solid #ffffff;
    width: 0;
    height: 0;
  }

  @media only screen and (max-width: 600px) {
    .Plantel > div {
        display: none;
    }
    .Plantel > main{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Plantel > main > a{
        width: 80%;
        height: auto;
    }
  }