.maincontentholder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.maincontent img {
  max-width: 100%;
  height: auto;
}

.maincontent button {
  background-color: #343030;
  color: white;
  padding: 7px 10px;
  border-top-right-radius: 30px;
  border-width: 3px;
  position: relative;
  left: 5px;
  bottom: 47px;
  cursor: pointer;
  font-size: 19px;
  font-weight: bolder;
  width: 300px;
}
.maincontent button a:hover {
  color: red;
  
}
* a{
  text-decoration: none;
  color: white;
}

.links {
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  bottom: 30px;
}

.links img {
  height: 100%;
}

.links >div>h2 {
  font-size: 20px;
}

.links > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.weather {
  width: 300px;
  height: 100%;
}

.weather p {
  margin: 5px 0;
}

.links a {
  color: blue;
  text-decoration: none;
}
.estadiofs {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: #0000008f;
  top: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}
.estadiofs button img,
.estadiofs button {
  width: 56vw;
  height: auto;
}
.right_img,
.left_img {
  height: 83vh;
}
.right_img img,
.left_img img {
  height: 100%;
  width: 175px;
}
.inicio > section > div:nth-child(1){
  width: 90%;
  justify-content: center;
  align-items: center;
}
.inicio > section{
  width: 100%;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .maincontent {
    width: 80vw;
    margin: auto;
  }
  .maincontentholder{
    margin-bottom: 40px;
    margin-top: 20px;
  }
  .links {
    width: 100%;
    gap: 20px;
  }
  .maincontent>.maincontentholder > h2 {
    font-size: 9vw;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 14vw;
    padding-right: 15vw;
  }
  .links > div > h2{
    font-size: 10vw;
  }
  .maincontent button {
    width: 50%;
    font-size: 2.8vw;
    padding: 5px 5px 1px 5px;
    bottom: 25px;
    border-width: 2px;
  }
  .header nav ol li {
    width: auto;
  }
}
