@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: opera;
  src: url("./fonts/Opera-Demo.otf");
}
body,
html,
#root,
.main-component {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: white;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.main-component {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  background-color: white;
}

.header {
  /* display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 15vh; */
  position: relative;
  z-index: 10;
}
.header > div{
  height: 42px!important;
}

.logo img {
  height: 120px;
  width: auto;
  z-index: 10;
  position: relative;
  top: 10px;
}
.header nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #1f1f1f;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  height: 40px;
}

.header nav ol {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
}

.header nav ol li {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  width: 200px;
  height: 98%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

header > nav > ol > li:nth-child(1) {
  border-left: none;
  width: 400px;
}
header > nav > ol > li:last-child {
  border-right: none;
}

.header nav ol li a, .header nav ol li {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}

.header nav ol li a:hover, .header nav ol li:hover {
  color: #ff0000;
}
textarea{
  width: 90%;
}
.publicites {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  height: 78vh;
  pointer-events: none; /* Esto asegura que los anuncios no interfieran con los clics */
}

.publicites img {
  height: auto;
  max-height: 100%;
  pointer-events: auto; /* Esto permite hacer clic en los anuncios */
}
.main-content {
  max-width: 900px;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
}
.main-content h2 {
  font-size: 3vw;
  margin-bottom: 10px;
  width: fit-content;
  font-family: "opera";
  font-weight: 900;
  color: white;
  background-color: rgb(255, 0, 0);
  padding: 5px 15px;
  position: relative;
}
.cornerRB::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: 14px solid transparent;
  border-right: 14px solid white;
  width: 0;
  height: 0;
}
.cornerLT::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 14px solid transparent;
  border-left: 14px solid white;
  width: 0;
  height: 0;
}
.inicio {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 85vh;
  flex-direction: row;
}
.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #1F1F1F;
  border: 1px solid #ccc;
  list-style: none;
  padding: 10px;
  display: none;
}

.dropdown > ul.dropdown-menu > li {
  margin: auto;
    width: 80%;
    height: fit-content;
    background-color: red;
    border: none;
    position: relative;
    padding: 1px;
    margin: 4px 0;
}
.dropdown > .dropdown-menu > li > a:hover{
  color: #1F1F1F;
}

.dropdown:hover .dropdown-menu {
  display: block;
    width: 100%;
    padding: 5px 0;
    height: 100px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    align-items: center;
}
.cornerRBM::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid #1F1F1F;
  width: 0;
  height: 0;
}
.cornerLTM::before {
  content: "";
  position: absolute;
    top: 0;
    left: 0;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #1F1F1F;
    width: 0;
    height: 0;
}