.maincontent{
    align-items: center;
}
div.maincontent > h2{
    font-size: 4vw;
}
.maincontent > p{
    width: 88%;
    border-width: 1px;
    border-style: solid;
    background-color: white;
    padding: 10px;
    font-weight: 500;
}
@media only screen and (max-width:600px){
    div.maincontent > h2{
        font-size: 12vw;
    }
}