.player {
  text-align: center;
  position: absolute;
  background-color: #f2f2f2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
label{
  color: red;
  font-size: 1.5rem;
  font-weight: 700;
}

.player h1 {
  font-size: 6rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
    position: absolute;
    z-index: 20;
    top: 0;
    left: 200px;
    text-shadow: 2px 2px 0 black;
}
.player h1:nth-child(3){
  top: 100px;
  color: rgb(207, 0, 0);
  border-color: black;
  font-size: 9rem;
}

.monu{
  width: 100vw;
  height: 100%;
  filter: brightness(0.5);
}
.pImg{
  position: absolute;
  bottom: 0;
  right: 7vw;
  z-index: 20;
  height: 63vh !important;
}
.player h2 {
  font-size: 2rem;
}

.player>div:first-child {
  height: 73vh;
  position: relative;
  z-index: 3;
}
section{
  width: 70vw;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  z-index: 3;
  position: relative;
}
.data{
  height: 200px;
  width: 36vw;
  display: flex;
  padding: 0 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 40px;
}
section div > ol{
  padding: 10px;
  width: 30%;
}

 section > div:nth-child(1) > img{
  width: 100%;
  height: auto;
}
section > div:nth-child(2){
  width: 60% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: -webkit-fill-available;
  justify-content: space-evenly;
}

section >div:nth-child(1){
  width: 40%;
}
@media only screen and (max-width: 600px) {
  .monu{height: 320px;}
  .player{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .player > div:nth-child(1){
    height: fit-content;
    overflow: hidden;
  }
  .player h1{
    left: 25px;
    font-size: 3rem !important;
    position: absolute;
    z-index: 10;
    top: 45%;

  }
  .player h1:nth-child(3){
    top: 60%;
  }
  .player > div:nth-child(1) > div > img{
    position: relative;
    top: -324px;
    left: 130px;
    height: 320px;
    width: auto;
  }
  .player > div:nth-child(1) > div{
    height: 0;
    width: 0;
  }
  section > div:nth-child(1){
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .data{
    width: 80vw;
    padding: 0;
    justify-content: center;
    height: 135px;
  }
  section div > ol{
    width: 45%;
  }
  .test{
    position: absolute;
    top: 30px;
  }
  .test ol{
    display: flex;
        flex-direction: row;
        width: 60px;
        align-items: center;
        justify-content: space-between;
        height: 50px;
  }
  .test ol label{
    color: white;
    font-size: 3rem;
    font-style:italic ;
  }
  .test ol h2{
    color: rgb(0, 0, 0);
    font-size: 3rem;
    font-style:italic ;
  }
  section > div > div > ol > h2 {
    margin: 10px 0;
  }
}
