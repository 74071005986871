/* Contacto.module.css */

/* Estilos generales */
.container {
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .title {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
  }
  
  .input,
  .textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .textarea {
    resize: none;
    height: 100px;
  }
  
  .button {
    width: 100%;
    padding: 10px;
    background-color: #ff0000;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 600;
  }
  
  .button:hover {
    background-color: #d30404;
  }
  @media only screen and (max-width: 600px){
   .container{
    display: flex;
    align-items: center;
    height: 75vh;
    justify-content: center;
   } 
    .form{
    height: fit-content;
    width: 80vw;
  }
}