/* src/components/HamburgerMenu.css */
.hamburger-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.menu-content {
  background-color: #1F1F1F;
  z-index: 30;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: -100vh; /* Coloca el menú fuera de la pantalla por defecto */
  left: 0;
  transition: top 0.3s ease-in-out; /* Añade una transición suave */
  
}

.menu-content.open {
  top: 0; /* Mueve el menú a la vista cuando está abierto */
}
li.menu > div > div > ol{
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#root > div > header > nav > ol > li.menu > div > div >ol> li a {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 10vw;
  width: 100%;
}

#root > div > header > nav > ol > li.menu > div > div  li {
  height: 50px;
  width: 100%;
  margin: auto;
  border: none;
}

.menu-content a:hover {
  background-color: #eee;
}