
.match > ol > li{
  width: 25px;
  height: 25px;
  background-color: red;
  color: white;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixture {
  display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}


.round {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  justify-content: space-around;
  margin: 0 20px;
}

.match {
  background-color: #1f1f1f;
    color: white;
    font-weight: 700;
    border: 0;
    margin: 10px 0;
    width: 80%;
    height: 30px;
    position: relative;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.match ol{
  list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-evenly;
    margin: 5px;
}
.match ol li{
  margin: 0 5px;
  width: 10%;
}
.match >ol >div{
  width: 40%;
}
.fixture >div:nth-child(2){
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: stretch;
  width: 80%;
  margin: auto;
}
.fixture ol {
  display: grid;
    width: 100%;
    text-align: center;
    padding: 0;
    grid-template-columns: repeat(2, 50%);
    margin: 0 auto;
    justify-items: center;
}
.fixture > div > div:last-child > ol{
      
      display: flex;
      justify-content: center;
      width: 50%;
}
.fixture h2{
  font-size: 3vw;
    width: 100%;
    width: fit-content;
    font-family: "opera";
    font-weight: 900;
    color: white;
    background-color: rgb(255, 0, 0);
    padding: 5px 15px;
    position: relative;
    margin: 0 auto 10px auto;
}
.fixture >div:nth-child(1) {
  width: 80%;
}



.match > ol:nth-child(1){
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: space-evenly;
}
.match > div{
  /* time */
  height: 25px;
  width: fit-content;
  padding: 0 5px;
}
@media only screen and (max-width: 600px) {
  .fixture{
    width: 90vw;
    margin: 50px auto;
  }
  .fixture > div{width: 100% !important;}
  .fixture > div> ol{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .fixture h2{
    font-size: 3rem;
    width: 100%;
    padding: 0;
    margin: 40px auto 10px auto;
  }
  .match{
    width: 100%;
    height: auto;
    padding: 10px ;
  }
  .match >ol>div{
    width: 45%;
  }
  .match>ol>li{
    width: 10%;
  }
}