.autogestion_container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.autogestion_container h1 {
  text-align: center;
  color: #333;
}

.autogestion_container h2 {
  color: #555;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.autogestion_container h3 {
  color: #666;
  margin: 10px 0;
}

.autogestion_container .autogestion_section:nth-child(2){
  display: flex;
  flex-direction: column;
  align-items: center;
}

.autogestion_container .autogestion_section:nth-child(2) div input{
width: 250px;
}
.autogestion_container .autogestion_section:nth-child(2) div{
gap: 50px;
}

.autogestion_container input[type="text"],
.autogestion_container input[type="number"],
.autogestion_container input[type="date"],
.autogestion_container input[type="time"],
.autogestion_container select {
  width: 13%;
  padding: 8px;
  margin: 5px 0 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.autogestion_container input[type="number"]{
  width: 40px;
}

.autogestion_container input[type="text"]:focus,
.autogestion_container input[type="number"]:focus,
.autogestion_container input[type="date"]:focus,
.autogestion_container input[type="time"]:focus,
.autogestion_container select:focus {
  border-color: #007bff;
  outline: none;
}

.autogestion_container button {
  padding: 10px 15px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.autogestion_container button:hover {
  background-color: #0056b3;
}

.autogestion_container button.delete_button {
  background-color: #dc3545;
}

.autogestion_container button.delete_button:hover {
  background-color: #c82333;
}

.autogestion_container img {
  max-width: 100px;
  height: auto;
  display: block;
  margin-top: 10px;
}


.autogestion_container .autogestion_section {
  margin-bottom: 30px;
}

.autogestion_container .autogestion_section div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.autogestion_container .autogestion_section div img {
  margin-left: 10px;
}

.autogestion_container .autogestion_section div button {
  margin: 0;
}
div.autogestion_container > div:nth-child(9) > div{
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .autogestion_container {
      padding: 5px;
      width: 90vw;
      margin: auto;
  }

  .autogestion_container .autogestion_section {
      margin-bottom: 15px;
  }

  .autogestion_container input[type="text"],
  .autogestion_container input[type="number"],
  .autogestion_container input[type="date"],
  .autogestion_container input[type="time"],
  .autogestion_container select {
      font-size: 12px;
      width: 80%;
      padding: 5px;
  }


 

  .autogestion_container button {
      font-size: 12px;
      padding: 6px 10px;
  }

  .autogestion_container .player_container {
      padding: 5px;
  }

  .autogestion_container .player_container input {
      margin-bottom: 3px;
  }

  .autogestion_container .player_container img {
      max-width: 100px;
  }

  .autogestion_container .autogestion_section div {
      flex-direction: column;
  }
}
