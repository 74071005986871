/* Sponsors.module.css */

.container {
    padding: 40px;
    border-radius: 10px;
    text-align: center;
  }
  
  .title {
    font-size: 3vw;
    width: -moz-fit-content;
    width: fit-content;
    font-family: "opera";
    font-weight: 900;
    color: white;
    background-color: rgb(255, 0, 0);
    padding: 5px 15px;
    position: relative;
    margin: 0 auto 10px auto;
  }
  
  .sponsorsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    justify-content: center;
  }
  
  .sponsorCard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    padding: 20px;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .sponsorCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .logo {
    width: 120px;
    height: 120px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .name {
    font-size: 18px;
    color: #555;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 600px) {
    .title{
      font-size: 10vw;
    }
  }